
import '../assets/css/nds.css';

import React, { Component } from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Steps from "../components/scstep"
//import bottomLine from "../images/line.png"
//import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyAuto from "../components/MyAuto";
import MyInputMask from "../components/MyInputMask";
import MyCheckbox from "../components/MyCheckbox";
import ButtonLoader from "../components/buttonLoader";
import { zonar } from './../components/constant'

class sc1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      issc:false,
      isAgent:false,
      nds1Data:{name:'',street:'',city:'',state:'',zip:'',phone:'',email:'',
      taxId:'',website:'',bo_name:'',print_name:'',title:''}
    };
  }

  componentDidMount = async () => {
    const { showLoader, hideLoader } = this.props;
    if(!sessionStorage.ndsver || sessionStorage.ndsver!==process.env.ndsver){
      sessionStorage.clear();
      sessionStorage.ndsver=process.env.ndsver;
    }
    
    if(!sessionStorage.nds1Data){
      sessionStorage.nds1Data = window.btoa(JSON.stringify(this.state.nds1Data))
    } 
    this.state.nds1Data = JSON.parse(window.atob(sessionStorage.nds1Data))
    let issc = false;
    if(sessionStorage.issc === 'yes')
      issc = true;

    let isAgent = false;
    if(sessionStorage.isAgent === 'yes')
    isAgent = true;

    this.setState({issc:issc,isAgent:isAgent,nds1Data:JSON.parse(window.atob(sessionStorage.nds1Data))});
    
    showLoader();
    hideLoader();
    
  }

  isSC = (value) => {
    this.setState({issc:value});
  }
  isAgent = (value) => {
    this.setState({isAgent:value});
  }

  sugChange = async(value,type) =>{
    let nds1Data = this.state.nds1Data;
    nds1Data.state = value;
    this.setState({nds1Data:nds1Data});
    this.enableButton();
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  }
  enableButton = () => {
    if(this.state.nds1Data.state)
    this.setState({ canSubmit: true,infoError:"" });
    else
    this.setState({ canSubmit: false,infoError:"" });
  }
  submit = (model) => {
    const { showLoader, hideLoader} = this.props;
    let nds1Data = this.state.nds1Data;

    showLoader();
    model.state = nds1Data.state;
    sessionStorage.nds1Data = window.btoa(JSON.stringify(model));
    if(this.state.issc)
      sessionStorage.issc = 'yes';
    else
      sessionStorage.issc = 'no';

      sessionStorage.isAgent = this.state.isAgent?'yes':'no';
    hideLoader();
    navigate("/sc3")
  }
  
   
  render() {
    const { nds1Data, canSubmit, issc,isAgent } = this.state;
    const { buttonLoader} = this.props;
    
    return (
      <Layout layoutType="nds" >
        <section className="ndsec scout">
          <Steps></Steps>
          <div className="custom-container vehinfo">
            <div className="title-container">
              {/* <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div> */}
              <div className="title-text">
                <h2>PRODUCER INFORMATION</h2>
                <p></p>
              </div>
            </div>

            <div className="body-content">
              <Formsy ref="nds1Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                  
                    <div className="form-container-inline">
                        <div className="form-label-group error">
                            <MyInput name="name" id="name" placeholder="Dealership Name"  value={nds1Data.name} validationError="Please enter a valid name."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:50}} required />
                        </div>

                    </div>
                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInput name="street" id="street" placeholder="Street Address"  value={nds1Data.street} validationError="Please enter a valid street."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:50}} required />
                        </div>
                    </div>
                
                    <div className="form-container-inline">
                        <div className="form-label-group error">
                            <MyInput name="city" id="city" placeholder="City"  value={nds1Data.city} validationError="Please enter a valid city."
                                isLabelRequired={true} className="form-control" validations={{minLength: 3,maxLength:25}} required />
                        </div>

                    </div>
                
                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <label>State*</label>
                        <MyAuto value={nds1Data.state} options={zonar}  sugChange={(e) => this.sugChange(e, 'zone')} name="zone" />
                        </div>
                    </div>
                
                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInputMask name="zip" id="zip" placeholder="Zip Code" mask="99999" value={nds1Data.zip} isLabelRequired={true}
                            className="form-control" validations={{minLength: 5}} validationError="Please enter a valid zipcode." required />
                        </div>

                    </div>
                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInputMask name="phone" id="phone" placeholder="Phone" mask="999-999-9999" value={nds1Data.phone} isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." required />
                        
                        </div>
                    </div>
            
                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInput name="bo_name" id="bo_name" placeholder="Business Owner Name" value={nds1Data.bo_name} validations={{minLength: 3,maxLength:25}}
                        isLabelRequired={true} className="form-control"  validationError="Please enter a valid name." required  />
                        </div>

                    </div>

                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInput name="email" id="email" placeholder="Business Owner Email"  value={nds1Data.email} validations="isEmail"
                                isLabelRequired={true} className="form-control" validationError="Please enter valid email" required />
                        </div>
                    </div>
                    
                    
                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInput name="title" id="title" placeholder="Owner Title"  value={nds1Data.title} isLabelRequired={true} className="form-control" 
                        validations={{minLength: 1,maxLength:25}}  required  validationError="Please enter a valid title." />
                        </div>

                    </div>

                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInput name="taxId" id="taxId" placeholder="Dealership Tax ID" value={nds1Data.taxId} isLabelRequired={true} className="form-control" required  />
                        
                        </div>

                    </div>

                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInput name="website" id="website" placeholder="Dealership Website"  value={nds1Data.website} isLabelRequired={true} className="form-control"  />
                        </div>
                    </div>

                    

                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInput name="sales_name" id="sales_name" placeholder="Rep/Agent Name" value={nds1Data.sales_name} isLabelRequired={true} className="form-control" required 
                        validations={{minLength: 1,maxLength:25}}  validationError="Please enter a valid name." />
                        </div>
                    </div>

                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInputMask name="sales_phone" id="sales_phone" placeholder="Rep/Agent Phone" mask="999-999-9999" value={nds1Data.sales_phone} isLabelRequired={true}
                            className="form-control" validations={{minLength: 12,maxLength:15}} validationError="Please enter a valid Phone." required={isAgent} />
                        </div>
                    </div>

                    <div className="form-container-inline">
                        <div className="form-label-group error">
                        <MyInput name="sales_email" id="sales_email" placeholder="Rep/Agent Email" value={nds1Data.sales_email} isLabelRequired={true} 
                        className="form-control" required validations="isEmail" validationError="Please enter valid email"  />
                        </div>
                    </div>
                    
                    
                    
                    

                    <div className="form-container-inline">
                    <div className="myform">
                      <div className="check-box-container">
                      
                      <div className="form-check" >
                        <MyCheckbox
                          name="isAgent" id="isAgent" labelClass="check-container big" value={isAgent}
                          label="Please select if the above information is filled out by an agent." checkBoxChange={(e) => this.isAgent(e)}
                        />
                            </div>
                      
                      </div>
                    </div>
                  </div>

                    <div className="form-container-inline" style={{ display: 'none' }}>
                    <div className="myform">
                      <div className="check-box-container">
                      
                      <div className="form-check" >
                        <MyCheckbox
                            name="sc" id="sc" labelClass="check-container big" value={issc}
                            label="Do you also want to sign up with Servicecontract.com?" checkBoxChange={(e) => this.isSC(e)}
                        />
                            </div>
                      
                      </div>
                    </div>
                  </div>

                    <div className="form-container-inline">
                      <div className="btn-container">
                        <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                            <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                        </button>
                      </div>
                    </div>

                    

              </Formsy>
              <div className="txtright">* Field is required</div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(sc1);